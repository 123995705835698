<template>
	<div class="contanierBox">
		<div class="filter-container title-border">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字:</label>
				<el-input v-model="searchKey" placeholder="商品名称、条形码" clearable style="width:300px"></el-input>
			</div>
			<!-- button -->
			<div class="filter-item"><el-button type="primary" style="margin:0 30px;width:90px;" @click="handleFilter">查询</el-button></div>
		</div>
		<!-- table -->
		<div class="table-container content" style="max-height:500px;overflow: auto;">
			<!-- :row-key="getRowKeys" -->
			<!-- @select='onTableSelect'  -->
			<el-table :data="tableDataList" style="width: 100%" :loading="loading" ref="compSelectProTable" @selection-change="handleSelectionChange" empty-text="筛选结果为空">
				<!-- <el-table-column type="selection" width="55" reserve-selection>
				</el-table-column> -->
				<el-table-column prop="Name" label="商品">
					<template slot-scope="scope">
						<div style="display: flex;align-items: center;">
							<img style="width: 64px;height: 64px;" v-if="scope.row.ImgUrl" :src="imgBase + scope.row.ImgUrl + '@!cut64'" />
							<!-- <div>{{scope.row.ProductName}}</div> -->
							<div style="width:500px;margin-left: 10px;align-items: center;">
								<div
									style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;"
									>{{ scope.row.ProductName }}
								</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="180">
					<template slot-scope="scope">
						<el-button type="text" @click="showMaterial(scope.row)" class="pointers">查看素材</el-button>
						<el-button style="margin-left: 15px;" type="text" @click="getMaterialInfo(scope.row)" class="pointers">导入素材</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				v-if="page.total"
				style="margin-top:20px;float:right;"
				@size-change="handleSizeChange"
				@current-change="handleSizeChange"
				:current-page="page.current"
				:page-sizes="[10, 10, 20, 30, 40, 50]"
				:page-size="page.size"
				layout="total, sizes, prev, pager, next, jumper"
				:total="page.total"
			></el-pagination>
		</div>
	</div>
</template>
<script>
import config from '@/config/index.js';
import { sharedMaterialList, sharedMaterialInfo } from '@/api/goods';
export default {
	name: 'importMaterial',
	props: {},
	data() {
		return {
			searchKey: '',
			page: {
				total: 0,
				current: 1,
				size: 10
			},
			tableDataList: [],
			multipleSelection: [],
			checkedShopPro: false,
			isReady: false,
			imgBase: config.IMG_BASE,
			materialDetailVisables: false,
			materialInfo: {}
		};
	},
	created() {
		this.getsharedMaterialList();
	},
	mounted() {},
	watch: {},
	methods: {
		//获取素材详情
		async getsharedMaterialInfo(item) {
			try {
				let result = await sharedMaterialInfo({ SharedMaterialId: item.SharedMaterialId });
				if (result.IsSuccess) {
					this.materialInfo = result.Result;
					this.$emit('showMaterialDetail', item, this.materialInfo);
				}
			} catch (e) {
				console.log(e);
			} finally {
			}
		},

		async getsharedMaterialList() {
			this.loading = true;
			try {
				let data = {
					KeyWords: this.searchKey, // 搜索关键字，商品名称或条形码
					Skip: (this.page.current - 1) * this.page.size, // 略过数据
					Take: this.page.size // 取的数据
				};
				let result = await sharedMaterialList(data);
				this.page.total = result.Result.Total;
				this.tableDataList = result.Result.Results;
			} catch (error) {
				console.log(error);
			} finally {
				this.loading = false;
			}
		},

		handleSelectionChange(val) {
			this.selectCount = val.length;
			this.multipleSelection = val;
		},
		// 切换显示条数
		handleSizeChange(val) {
			this.page.current = val;
			this.getsharedMaterialList();
		},
		// 翻页
		handleCurrentChange(val) {
			this.page.size = val;
			this.getsharedMaterialList();
		},

		handleFilter() {
			this.page.current = 1;
			this.getsharedMaterialList();
		},

		//关闭弹框，以及曝光选择的列表
		getMaterialInfo(row) {
			this.$emit('getMaterialInfo', row);
		},
		showMaterial(item) {
			this.getsharedMaterialInfo(item);
		}
	}
};
</script>
<style lang="less">
.contanierBox {
	position: relative;
	overflow: hidden;
	.dialog-footer {
		margin-top: 20px;
		text-align: center;

		.button {
			width: 150px;
		}
	}
}
.content {
	overflow: hidden;
}
</style>
